
import { defineComponent, ref, reactive, toRefs, h } from "vue";
import { Moment } from "moment";
import JobTaskDetailDrawer from "../components/JobTaskDetailDrawer.vue";
import taskCenter from "@/API/taskCenter/index";
import { findByGroupCode } from "@/API/communication/appeal";
import fixTable from "@/hooks/checking/useFixTable";

enum Status {
  "Initializing" = 1,
  "Processing",
  "Completed",
}

export default defineComponent({
  components: {
    // JobTaskDetailModal,
    JobTaskDetailDrawer,
  },
  setup() {
    const pagination = reactive({
      total: 0,
      currentPage: 1,
      pageSize: 20,
    });
    const operationName = ref<string | null>(null);
    const selectedOperationName = ref<string | null>(null);
    const operationNameList = ref<any[]>([]);
    const operationNameMap = reactive<any>({});
    findByGroupCode({ groupCode: "task_center_operation_name" }).then(
      (res: any) => {
        operationNameList.value = res;
        res.forEach((item: any) => {
          operationNameMap[item.itemCode] = item.itemNameEn;
        });
      }
    );
    const status = ref<string | null>(null);
    const statusList = ref<any[]>([]);
    findByGroupCode({ groupCode: "task_center_status" }).then((res: any) => {
      statusList.value = res;
    });
    const operationTime = ref<string[]>([]); // 格式化字符串
    const operationTime2 = ref<Moment[]>([]); // 用于日期组件双向绑定
    //* 限定表格
    const height: number = 100 + 50 + 10 + 145 + 30 + 50 + 10;
    const tableHeight = fixTable(height);
    // const renderStatus = ({ text }: any) => {
    //     return h('span', `${Status[text]}`);
    // };
    const columns = [
      {
        title: "Operation Name",
        dataIndex: "operationName",
        slots: {
          customRender: "operationName",
        },
      },
      {
        title: "Operation Time",
        dataIndex: "operationTime",
      },
      {
        title: "Task Start Time",
        dataIndex: "taskStartTime",
      },
      { title: "Task Completed Time", dataIndex: "taskCompletedTime" },
      {
        title: "Status",
        dataIndex: "statusName",
        // customRender: renderStatus,
      },
      {
        title: "Description",
        dataIndex: "description",
      },
      {
        title: "Operation",
        dataIndex: "operation",
        slots: {
          customRender: "operation",
        },
      },
    ];
    const tableData = ref([]);
    const modalVisible = ref<boolean>(false);
    const dataSource = ref<any>([]);

    const onChange = (value: Moment[], dateString: string[]) => {
      operationTime.value = dateString;
    };

    const showSetDealerInfo = ref(false);
    const showStatus = ref(false);
    const setDealerCommunication = ref();
    const totalQty = ref<string>("");
    const showDetail = (record: any) => {
      taskCenter
        .getDetails({ params: { taskCenterId: record.id } })
        .then((res) => {
          const temp = res.taskCenterDetails;
          res.setDealerCommunication &&
            (setDealerCommunication.value = res.setDealerCommunication);
          totalQty.value = res.totalQty;
          temp.push({});
          dataSource.value = temp;
          selectedOperationName.value = operationNameMap[record.operationName];
          modalVisible.value = true;
          if (record.operationName === "4") {
            showSetDealerInfo.value = true;
          } else {
            showSetDealerInfo.value = false;
          }
          if (["6", "7"].includes(record.operationName)) {
            showStatus.value = true;
          } else {
            showStatus.value = false;
          }
        });
    };

    const close = () => {
      modalVisible.value = false;
    };

    const getTableData = () => {
      const params = {
        //TODO 用户只能看到自己创建的Task
        current: pagination.currentPage - 1,
        size: pagination.pageSize,
        operationName: operationName.value,
        operationTimeFrom: operationTime.value[0],
        operationTimeTo: operationTime.value[1],
        status: status.value,
      };
      taskCenter.getTaskCenterList(params).then((res) => {
        tableData.value = res.content;
        pagination.total = res.totalElements;
      });
    };

    const reset = () => {
      operationName.value = null;
      status.value = null;
      operationTime.value = [];
      operationTime2.value = [];
    };

    const pageChange = (page: number) => {
      pagination.currentPage = page;
      getTableData();
    };

    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageSize = pageSize;
      pagination.currentPage = page;
      getTableData();
    };

    return {
      tableHeight,
      ...toRefs(pagination),
      operationName,
      selectedOperationName,
      operationNameList,
      operationNameMap,
      status,
      statusList,
      operationTime,
      operationTime2,
      columns,
      tableData,
      modalVisible,
      dataSource,
      showSetDealerInfo,
      totalQty,
      getTableData,
      reset,
      showDetail,
      close,
      onChange,
      pageChange,
      sizeChange,
      setDealerCommunication,
      showStatus,
    };
  },
});
