
import { defineComponent, h, toRefs, computed } from "vue";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    selectedOperationName: {
      type: String,
      required: true,
      default: "",
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    dataSource: {
      type: Array,
      required: true,
      default: () => [],
    },
    totalQty: {
      type: String,
      required: true,
      default: "",
    },
    showSetDealerInfo: {
      type: Boolean,
      required: true,
      default: false,
    },
    showStatus: {
      type: Boolean,
      required: true,
      default: false,
    },
    setDealerCommunication: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { dataSource } = toRefs(props);

    const dataLen = computed(() => {
      return dataSource.value.length - 1;
    });

    // 表格渲染配置
    const renderNo = ({ index }: any) => {
      const obj = {
        children: index + 1,
        props: {} as any,
      };

      if (index === dataLen.value) {
        return h("span", { class: "weight" }, `Total`);
      }

      return obj;
    };
    const renderQty = ({ text, index }: any) => {
      const obj = {
        children: text,
        props: {} as any,
      };

      if (index === dataLen.value) {
        return h("span", { class: "weight" }, (props as any).totalQty);
      }

      return obj;
    };
    const columns = [
      {
        title: "No.",
        customRender: renderNo,
      },
      {
        title: "Program Code",
        dataIndex: "programCode",
      },
      {
        title: "Program Category",
        dataIndex: "programCategory",
      },
      {
        title: "Qty",
        dataIndex: "qty",
        customRender: renderQty,
      },
    ];
    const renderStatus = ({ text, index }: any) => {
      const obj = {
        children: text,
        props: {} as any,
      };

      if (index === dataLen.value) {
        return h("span", { class: "weight" }, `Total`);
      }

      return obj;
    };
    const columns2 = [
      {
        title: "Status",
        dataIndex: "programCategory",
        customRender: renderStatus,
      },
      {
        title: "No.",
        dataIndex: "qty",
        customRender: renderQty,
      },
    ];

    const close = () => {
      emit("close");
    };

    const dataSource2 = computed(() => {
      const source = dataSource.value;
      const len = source.length;
      if (len === 0) {
        return [];
      } else {
        const usefulSource = source.slice(0, len - 1);
        return [
          ...usefulSource
            .sort((a: any, b: any) =>
              a.programCategory.localeCompare(b.programCategory)
            )
            .reverse(),
          {},
        ];
      }
    });

    return { columns, columns2, close, renderNo, dataSource2 };
  },
});
