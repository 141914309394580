import { fetchGet, fetchPost } from '../index';

// 获取taskcenter 列表
const getTaskCenterList = (params: any): Promise<any> => {
    return fetchPost('/claimapi/claimChecking/taskCenter/list', params);
};

const getDetails = (params = {}): Promise<any> => {
    return fetchGet(
        '/claimapi/claimChecking/taskCenterDetail/taskCenterDetailList',
        params
    );
};
// system-taskcenter
const getTaskCenterDetail = (id: string): Promise<any> => {
    return fetchGet(`/rvapi/taskCenter/getDetail?id=${id}`);
};
const getTaskStatus = (): Promise<any> => {
    return fetchGet(`/rvapi/taskCenter/getTaskStatus`);
};
const getTaskType = (): Promise<any> => {
    return fetchGet(`/rvapi/taskCenter/getTaskType`);
};
const fetchListPage = (params = {}): Promise<any> => {
    return fetchPost(`/rvapi/taskCenter/listPage`, params);
};

export default {
    getTaskCenterList,
    getDetails,
    getTaskCenterDetail,
    getTaskStatus,
    getTaskType,
    fetchListPage

};
